import React from 'react'
import {
  Address,
  Breadcrumbs,
  Contacts,
  Hero,
  Page,
} from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'contact.meta.title',
  description: 'contact.meta.description',
}

const Homepage = ({ pageContext }) => (
  <Page
    isHomepage
    meta={meta}
    locale={pageContext.locale}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: buildLocalizedPath('/contact/', pageContext.locale), name: 'contact.meta.title'},
      ]}
    />
    <Hero
      title="contact.hero.title"
      subtitle="contact.hero.subtitle"
      description="contact.hero.description"
    />
    <Contacts locale={pageContext.locale} />
    <Address />
  </Page>
)

export default Homepage